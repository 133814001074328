// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/icons/mts/icon-mts--field-error.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/icons/mts/icon-mts--field-delete.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v-phone-input-mts[data-v-8a7798ba]{position:relative}.v-phone-input-mts.error[data-v-8a7798ba]:after{content:\"\";position:absolute;right:12px;top:calc(50% - 12px);display:block;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:24px 24px;width:24px;height:24px;transition:.3s}.v-phone-input-mts.xl[data-v-8a7798ba] input{padding:13px 12px}.v-phone-input-mts__clear[data-v-8a7798ba]{position:absolute;right:12px;top:calc(50% - 12px);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:24px 24px;width:24px;height:24px;transition:.3s;cursor:pointer}.error .v-phone-input-mts__clear[data-v-8a7798ba]{display:none}.v-phone-input-mts[data-v-8a7798ba] input{position:relative;width:100%;border-radius:6px;padding:10px 12px;color:#1d2023;font-family:MTS Compact,Arial,sans-serif;font-size:17px;font-weight:400;line-height:24px;outline:none;border:1px solid #d7dae3;background:#f2f3f7;transition:all .3s ease}.v-phone-input-mts[data-v-8a7798ba] input::-webkit-input-placeholder{color:#626c77}.v-phone-input-mts[data-v-8a7798ba] input:-moz-placeholder,.v-phone-input-mts[data-v-8a7798ba] input::-moz-placeholder{color:#626c77}.v-phone-input-mts[data-v-8a7798ba] input:not(:focus):hover{border:1px solid #1d2023}.v-phone-input-mts[data-v-8a7798ba] input:focus{border:1px solid #2980ff}.v-phone-input-mts[data-v-8a7798ba] input:focus:-moz-placeholder,.v-phone-input-mts[data-v-8a7798ba] input:focus::-moz-placeholder,.v-phone-input-mts[data-v-8a7798ba] input:focus::-webkit-input-placeholder{color:#969fa8}.v-phone-input-mts[data-v-8a7798ba] .vti__dropdown{display:none}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
