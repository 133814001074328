<template>
  <div v-click-outside="onBlur" class="v-phone-input-mts">
    <client-only>
      <vue-tel-input v-bind="phoneProps" v-model="currentValue" @focus="onFocus" @validate="updateValue" />
    </client-only>
    <span v-if="currentValue && isFocused" class="v-phone-input-mts__clear" @click="clearValue"></span>
  </div>
</template>

<script>
import clickOutside from "@/directives/clickOutside";

const VueTelInput = () =>
  Promise.all([
    import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
    import(/* webpackChunkName: "chunk-vue-tel-input" */ '~/assets/scss/vue-tel-input-mts.scss'),
  ]).then(([{ VueTelInput }]) => VueTelInput);

export default {
  name: 'VPhoneInputMTS',
  directives: { clickOutside },
  components: {
    VueTelInput,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentValue: '',
      isFocused: false,
      validValue: '',
      phoneProps: {
        mode: 'international',
        defaultCountry: 'RU',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        validCharactersOnly: true,
        placeholder: '+7',
        enabledCountryCode: false,
        enabledFlags: true,
        preferredCountries: ['RU', 'BY', 'KZ', 'UZ', 'UA'],
        onlyCountries: [],
        ignoredCountries: ['AI', 'AS', 'AG', 'BM', 'VG', 'KY', 'GD', 'GU', 'JM', 'BB', 'VI', 'TC', 'TT', 'VC', 'LC', 'KN', 'MP', 'MS'],
        autocomplete: 'mobile tel',
        autoFocus: true,
        name: 'phone',
        id: 'phone',
        type: 'number',
        maxLen: 25,
        ref: 'phone',
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false,
          showFlags: false,
        },
        inputOptions: {
          showDialCode: true
        }
      }
    };
  },
  methods: {
    updateValue(telnumber) {
      if (telnumber) {
        if (telnumber.valid) {
          this.validValue = telnumber.number.international;
        } else {
          this.validValue = '';
        }
        this.emitValue(this.validValue);
      }
    },
    emitValue(valueNumber) {
      this.$emit('input', valueNumber);
    },
    onFocus() {
      this.isFocused = true;
      this.$emit('focus')
    },
    onBlur() {
      this.isFocused = false;
    },
    clearValue() {
      this.currentValue = '';
      this.emitValue('');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-phone-input-mts {
  position: relative;

  &.error:after {
    content: '';
    position: absolute;
    right: 12px;
    top: calc(50% - 12px);
    display: block;
    background-image: url("~/assets/img/icons/mts/icon-mts--field-error.svg");
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    transition: 0.3s;
  }
  &.xl {
    ::v-deep(input){
      padding: 13px 12px;
    }
  }
  &__clear {
    position: absolute;
    right: 12px;
    top: calc(50% - 12px);
    background-image: url("~/assets/img/icons/mts/icon-mts--field-delete.svg");
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    transition: 0.3s;
    cursor: pointer;

    .error & {
      display: none;
    }
  }

  ::v-deep(input) {
    position: relative;
    width: 100%;
    border-radius: 6px;
    padding: 10px 12px;
    color: $color-dark-2;
    font-family: $font-mts-compact;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    outline: none;
    border: 1px solid $color-gray-3;
    background: $color-gray-2;
    transition: all 0.3s ease;

    &::-webkit-input-placeholder {
      color: $color-gray-1;
    }

    &:-moz-placeholder {
      color: $color-gray-1;
    }

    &::-moz-placeholder {
      color: $color-gray-1;
    }

    &:not(:focus):hover {
      border: 1px solid $color-dark-2;
    }

    &:focus {
      border: 1px solid $color-blue;

      &::-webkit-input-placeholder,
      &:-moz-placeholder,
      &::-moz-placeholder {
        color: #969FA8;
      }
    }
  }

  ::v-deep(.vti__dropdown) {
    display: none;
  }
}
</style>
